import { animated, useSpring } from '@react-spring/web';
import IconProps from './IconProps';

function LoadingIcon(props: IconProps) {
  const { width = 66, height = 66, color = '#CF4500', ariaLabel } = props;

  const styles = useSpring({
    from: { strokeDashoffset: 197.82 },
    to: [{ strokeDashoffset: 0 }],
    loop: true,
    config: {
      duration: 750,
    },
  });

  return (
    <>
      <svg width={width} height={height} viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg" data-icon="loading-icon" aria-label={ariaLabel}>
        <animated.circle cx="32" cy="32" r="31.5" stroke={color} strokeDasharray={100} strokeDashoffset={styles.strokeDashoffset} />
      </svg>
    </>
  );
}

export default LoadingIcon;
