import { AnalyticsInstance } from 'analytics';
import { appStorage } from 'utils/storage';

let analytics: AnalyticsInstance;

export const init = (instance: AnalyticsInstance) => {
  analytics = instance;
};

const event = (name: string, payload?: any) => {
  const eventDetails =  {
    ...payload,
    connectPlusSessionId: appStorage.getItem('connectPlusSessionId'),
    market: appStorage.getItem('market')
  }
  analytics?.track?.(name, eventDetails)
};

const identify = (payload: any) => {
  if (analytics) {
    const userData = analytics.user();
    analytics?.identify?.(userData?.userId, payload);
  }
};


export const Track = {
  init,
  event,
  identify,
};
