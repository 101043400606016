import { useEffect, Suspense } from 'react';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { Loading } from 'components/Loading';
import { useFeatureFlagState } from 'hooks/useFeatureFlag';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,

    fallbackLng: 'en-US',
    supportedLngs: ['en-US', 'en-AU'],
    load: 'currentOnly',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    detection: {
      order: ['navigator', 'querystring', 'cookie', 'localStorage', 'sessionStorage'],
    },
  });


const I18nextProvider = ({ children }) => {
  const allFeatures = useFeatureFlagState();
  const { MARKET } = allFeatures.features

  useEffect(() => {

    if (MARKET === 'AUS') {
      document.documentElement.lang = 'en-AU'
    }

    window.onlanguagechange = function () {
      i18n.changeLanguage(navigator.language);
    };
  }, []);

  return <Suspense fallback={<Loading show={true} />}>{children}</Suspense>;
};

export { I18nextProvider };
