import { useState, useCallback } from 'react';

export interface ToggleFunction<T> {
  (): T;
  (value: T): T;
}

const defaultToggleFunction = (state: boolean): boolean => !state;

// Enhancement: Add proper typing to allow other types like string when toggleFunction is provided.
function useToggle<T>(initialValue: T = false as unknown as T, toggleFunction: ToggleFunction<T> = defaultToggleFunction as unknown as ToggleFunction<T>) {
  const [state, setState] = useState(initialValue);

  const toggleState = useCallback((value: T) => {
    setState((prevState: T) => {
      const isCallbackToggleValue = value !== undefined && typeof value === typeof initialValue;

      return isCallbackToggleValue ? value : toggleFunction(prevState);
    });
  }, []);

  return [state, toggleState] as [T, ToggleFunction<T>];
}

export { useToggle };
