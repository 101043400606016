import React from 'react';
import { toast } from 'react-toastify';
import { ErrorToast, ErrorToastProps } from './error';

function errorToast(props: ErrorToastProps) {
  return toast(<ErrorToast {...props} />, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    closeButton: false,
    theme: 'custom',
    className: '!bg-transparent !shadow-none !p-0 !m-0',
  });
}

export const Toast = {
  error: errorToast,
};
