import { useState, useEffect, useCallback } from 'react';

/**
 * Possible Enhancements:
 * Need to make it mobile first condition, since tailwind or any other css libraries other there in the real world uses mobile first approach.
 * We need to try reading it from tailwind.config.js, so that it will be in sync.
 * If possible, use `window.matchMedia` to get the screen size.
 * Just return the screenSize instead of returning a object.
 */

function getWindowDimensions() {
  const width = window?.innerWidth || null;
  const height = window?.innerHeight || null;

  return {
    width,
    height,
  };
}

function getScreenSize() {
  const { width } = getWindowDimensions();

  if (width && width <= 640) {
    return 'sm';
  }

  if (width && width <= 768) {
    return 'md';
  }

  return 'lg';
}
function useWindowSize() {
  const [screenSize, setScreenSize] = useState<'sm' | 'md' | 'lg'>(getScreenSize());

  const handleResize = useCallback(() => {
    const _screenSize = getScreenSize();

    setScreenSize(_screenSize);
  }, []);

  useEffect(() => {
    window?.addEventListener('resize', handleResize);

    return () => window?.removeEventListener('resize', handleResize);
  }, []);

  return {
    screenSize,
  };
}

export { useWindowSize };
