import React from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import clsx from 'clsx';

export interface Props extends LinkProps {
  variant?: string;
  color?: string;
  children?: React.ReactNode;
}

function Link(props: Props) {
  const { className, variant = 'contained', color = 'primary', children, ...restProps } = props;

  return (
    <RouterLink
      className={clsx(
        variant === 'contained' && 'flex justify-center rounded-3xl px-8 py-3 text-[15px] font-medium leading-[15px]',
        variant === 'outlined' && 'flex justify-center rounded-3xl px-8 py-3 text-[15px] font-medium leading-[15px] ',
        variant === 'text' && '',
        variant === 'link' && 'underline decoration-1 underline-offset-2',

        color === 'primary' && 'bg-primary text-light hover:bg-action-primary-hover',
        color === 'secondary' && 'border-2 border-primary bg-default text-primary',
        color === 'text' && 'text-primary',
        className
      )}
      data-testid="test-link"
      {...restProps}
    >
      {children}
    </RouterLink>
  );
}

export { Link };
